.custom-delete-button {
    background-color: #ff4d4f; /* Color de fondo personalizado */
    border-color: #ff4d4f; /* Color del borde personalizado */
    color: white; /* Color del texto */
    border-radius: 4px; /* Bordes redondeados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
}

.custom-delete-button:hover {
    background-color: #ff7875; /* Color de fondo al pasar el ratón */
    border-color: #ff7875; /* Color del borde al pasar el ratón */
}

button,
hr,
input {
    overflow: visible;
}

progress,
sub,
sup {
    vertical-align: baseline;
}

.botonix {
    padding-bottom: 100%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: -25%;
    left: 25%;
    pointer-events: none;
}

.halos {
    padding-bottom: 100%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 100%;
    pointer-events: none;
    transform: translate(0px, 200px);
}

.halos div {
    transform: scale(0.01);
    position: absolute;
    top: -50px;
    top: -17%;
    left: 0;
    width: 100%;
    height: 100%;
    border: 12px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: radialsub 12s infinite linear;
}

.halos div.delay {
    animation-delay: 4s;
}

.halos div.moredelay {
    animation-delay: 8s;
}

.float-wa {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #fff;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    box-shadow: 0 4px 8px rgba(0, 196, 131, 1);
}

.wa-icon {
    margin-top: 6px;
}

.botonix div {
    transform: scale(0.01);
    position: absolute;
    top: -50px;
    top: -17%;
    left: 0;
    width: 100%;
    height: 100%;
    border: 12px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: radialsub 12s infinite linear;
}

.botonix div.delay {
    animation-delay: 4s;
}

.botonix div.moredelay {
    animation-delay: 8s;
}

[type='checkbox'],
[type='radio'],
legend {
    box-sizing: border-box;
    padding: 0;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

details,
main {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

hr {
    box-sizing: content-box;
    height: 0;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
select {
    text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
    appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
    outline: ButtonText dotted 1px;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
}

textarea {
    overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

summary {
    display: list-item;
}

[hidden],
template {
    display: none;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
    /*BASE COLOR THEME*/
    --verde: #00c483;
    --amarillo: #ffbf43;
    --rojo: #ff6060;
    --dark_grey: #2e333e;
    --medium_grey: #82858b;
    --light_grey: #d0d4db;

    /*Tipografia*/
    --ftitle: font-family: 'Roboto', sans-serif;
    --ftexto: 'Lato', sans-serif;
    --h1: 38px;
    --h2: 30px;
    --h3: 24px;
    --h4: 18px;
    --h5: 16px;
    --h6: 16px;
    --p: 16px;
    --span: 14px;
    --big: 18px;

    /*btn*/
    --border: 1px;
    --padding: 8px 12px;
    --radius: 44px;
    --txtbtn: 16px;

    /*Colores adicionales*/
    --themecolor: #353b41;
    --darktheme: #0f9af0;
    --luztheme: #c7cbd1;
    --luzlink: #bca5e4;
    --darklink: #ff9800;
}

/*GRID MTHEME*/
.grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
}

.container.grid {
    padding: 0;
}

.container.grid:before {
    display: none;
}

.container > .grid {
    margin-left: -15px;
    margin-right: -15px;
}

.grid > div {
    text-align: center;
}

/* .mdoble {}

.mtriple {} */

.dos {
    grid-template-columns: 1fr 1fr;
    z-index: 1;
    position: relative;
}

.tres {
    grid-template-columns: 1fr 1fr 1fr;
}

.cuatro {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.cinco {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.seis {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.diez {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

/*Proporciones GRID*/
.unodos {
    grid-template-columns: 1fr 2fr;
}

.tresdos {
    grid-template-columns: 2fr 1fr;
}

.trescuatro {
    grid-template-columns: 1fr 1fr;
}

/* MEDIA QUERIES */
/* -- -- -- -- -- -- -- */
@media (max-width: 1000px) {
    .botonix {
        display: none;
    }

    .FirstText,
    .SecondText {
        padding: 10%;
    }
}
@media (max-width: 1200px) {
    /**/
    .container {
        max-width: 1140px;
    }

    .cinco {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .cinco > div:nth-child(5n + 1) {
        grid-column: 1 / 3;
    }

    .cinco > div:nth-child(5n + 2) {
        grid-column: 3 / 5;
    }

    .cinco > div:nth-child(5n + 3) {
        grid-column: 5 / 7;
    }

    .cinco > div:nth-child(5n + 4) {
        grid-column: 1 / 4;
    }

    .cinco > div:nth-child(5n + 5) {
        grid-column: 4 / 7;
    }

    .seis {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .diez {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    /*1200*/
}

@media (max-width: 992px) {
    /* .grid>div {} */

    .container {
        max-width: 960px;
    }

    .tres {
        grid-template-columns: 1fr 1fr;
    }

    .tres > div:last-child {
        grid-column: 1 / 3;
    }

    .cuatro {
        grid-template-columns: 1fr 1fr;
    }

    .cinco {
        grid-template-columns: 1fr 1fr;
    }

    .cinco > div:nth-child(5n + 1) {
        grid-column: 1 / 2;
    }

    .cinco > div:nth-child(5n + 2) {
        grid-column: 2 / 3;
    }

    .cinco > div:nth-child(5n + 3) {
        grid-column: 1 / 2;
    }

    .cinco > div:nth-child(5n + 4) {
        grid-column: 2 / 3;
    }

    .cinco > div:nth-child(5n + 5) {
        grid-column: 1 / 3;
    }

    .seis {
        grid-template-columns: 1fr 1fr;
    }

    .diez {
        grid-template-columns: 1fr 1fr;
    }

    .navbar-header {
        width: 100%;
    }

    /* .logotipo {}

    .navbar-header {} */
}

/*992*/
@media (max-width: 767px) {
    /**/
    :root {
        /*Tipografia*/
        --titulo: inherit;
        --parrafo: inherit;
    }

    .container {
        padding: 0;
        margin: 0 20px;
        width: auto;
    }

    .container > .grid {
        margin: 0;
    }

    /* .grid>div {} */

    .grid > div,
    .dos,
    .tres,
    .cuatro,
    .cinco,
    .seis,
    .diez {
        grid-template-columns: 1fr;
        grid-column: span 1;
    }

    .tres > div:nth-child(5n + 1),
    .tres > div:nth-child(5n + 2),
    .tres > div:nth-child(5n + 3) {
        grid-column: 1 / 2;
    }

    .cinco > div:nth-child(5n + 1),
    .cinco > div:nth-child(5n + 2),
    .cinco > div:nth-child(5n + 3),
    .cinco > div:nth-child(5n + 4),
    .cinco > div:nth-child(5n + 5) {
        grid-column: 1 / 2;
    }

    .doble.dos,
    .doble.tres,
    .doble.cuatro,
    .doble.cinco,
    .doble.seis,
    .doble.diez {
        grid-template-columns: 1fr 1fr;
    }

    .doble.tres > div:nth-child(3n + 1) {
        grid-column: 1 / 2;
    }

    .doble.tres > div:nth-child(3n + 2) {
        grid-column: 2 / 3;
    }

    .doble.tres > div:nth-child(3n + 3) {
        grid-column: 1 / 3;
    }

    .doble.cinco > div:nth-child(5n + 1),
    .doble.cinco > div:nth-child(5n + 3) {
        grid-column: 1 / 2;
    }

    .doble.cinco > div:nth-child(5n + 2),
    .doble.cinco > div:nth-child(5n + 4) {
        grid-column: 2 / 3;
    }

    .doble.cinco > div:nth-child(5n + 5) {
        grid-column: 1 / 3;
    }

    .triple.tres,
    .triple.seis,
    .triple.diez {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .triple.tres > div {
        grid-column: inherit;
    }

    .triple.cinco {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .triple.cinco > div:nth-child(5n + 1) {
        grid-column: 1 / 3;
    }

    .triple.cinco > div:nth-child(5n + 2) {
        grid-column: 3 / 5;
    }

    .triple.cinco > div:nth-child(5n + 3) {
        grid-column: 5 / 7;
    }

    .triple.cinco > div:nth-child(5n + 4) {
        grid-column: 1 / 4;
    }

    .triple.cinco > div:nth-child(5n + 5) {
        grid-column: 4 / 7;
    }

    .triple.cuatro > div:nth-child(4n + 4),
    .triple.diez > div:nth-child(10n + 10) {
        grid-column: 1 / 4;
    }

    .triple {
        grid-template-columns: 1fr 1fr 1fr;
    }

    /*768*/
}

/*Tools*/
.fr {
    float: right;
}

.fl {
    float: left;
}

.clear {
    clear: both;
}

.ctr {
    text-align: center;
}

.m0 {
    margin: 0;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.p0 {
    padding: 0;
}

.p10 {
    padding: 10;
}

.p20 {
    padding: 20;
}

.p30 {
    padding: 30;
}

.img {
    width: 100%;
}

.imgfull {
    max-width: 100%;
}

/* .view_full {}

.view_tablet {}

.view_mobile {} */

.container img {
    max-width: 100%;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-sm,
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 860px;
    }
}

@media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1320px;
    }
}

body {
    background: #fff;
    font-family: 'Roboto', sans-serif;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: 20px 0;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.web button {
    cursor: pointer;
}

.web a,
.web a:hover,
a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}

.mweb .logo a {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
}

.mweb .menu ul {
    list-style: none;
    display: flex;
}

.mweb .menu li {
    margin-left: 50px;
}

.mweb .menu li.ingresar a {
    background: var(--verde);
    border-radius: 14px;
    padding: 7px 24px;
    color: #fff;
}

.mweb .menu a {
    color: #a3a6ad;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.28px;
}

.mweb .menu li.active a,
.mweb .menu li:hover a {
    color: #fff;
}

.mweb .menu.redes a {
    font-size: 14px;
}

.mweb .menu a:hover {
    color: #fff;
}

/* Estilos del icono del menú móvil */
.mweb .mobile-menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.mweb .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
    transition: 0.4s;
}

/* sidebar */
.mweb .sidebar {
    background-color: var(--dark_grey);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 150px 0 50px;
    position: relative;
    overflow: hidden;
}

.mweb .sidebar .info {
    color: #fff;
}

.mweb .sidebar .info h1 {
    color: #fff;
    margin: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 102.187%;
    margin: 15px 0;
}

.mweb .sidebar .info p {
    color: #d0d4db;
    margin: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 160%;
    text-align: left;
}

.Palomita {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mweb .sidebar .center h1,
.mweb .sidebar .center p {
    text-align: center;
    line-height: 168.187%;
}

.mweb .sidebar .center p {
    width: 60%;
    margin: 0 auto;
}

.mweb .sidebar .center h1 {
    color: #fff;
}

.mweb .sidebar .center p {
    color: #d0d4db;
}

.mweb .sidebar .center h1 img {
    margin-right: 10px;
}

.mweb .sidebar .info p b {
    color: #d0d4db;
}

.mweb .sidebar .info p.chico {
    font-size: 14px;
    line-height: 168%;
}

.mweb .sidebar .info .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}

.mweb .sidebar .info .buttons .btn_mw {
    width: 100%;
    max-width: 280px;
    /* Ancho máximo para los botones */
    border-radius: 14px;
    padding: 10px 30px;
    margin-bottom: 10px;
    /* Espaciado inferior entre los botones */
    color: #fff;
    letter-spacing: 1.28px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.mweb .sidebar .info .buttons .btn_mw.red {
    background: #ff6060;
    border: 1px solid #ff6060;
}

.mweb .sidebar .info .buttons .btn_mw.trans {
    border: 1px solid #434751;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
}

.mweb .sidebar .info .buttons .recover-link {
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.mweb .sidebar .info .buttons .recover-link:hover {
    text-decoration: underline;
}

.mweb .sidebar .info .buttons .version {
    color: #fff;
    font-size: 12px;
    margin-bottom: 20px;
}

.mweb .sidebar .maestra {
    img {
        width: 370px;
        margin: 0 auto;
        display: block;
        position: relative;
        z-index: 111;
    }

    p {
        margin-top: 1em;
        color: #d0d4db;
        font-size: 20px;
        font-weight: 400;
        line-height: 160%;
    }
}

@keyframes radialsub {
    to {
        transform: scale(0.8);
        border: 4px solid rgba(255, 255, 255, 0);
    }
}

/* beneficios */
.mweb .beneficios {
    background-image: url('./assets/bg_beneficios.jpg');
    background-position: center;
    background-size: 700px 440px;
    background-repeat: repeat;
    padding: 70px 0;
}

.mweb .beneficios h1 {
    color: var(--rojo);
    font-size: 54px;
    font-weight: 700;
    text-align: center;
}

.mweb .beneficios h1 img {
    margin-bottom: 10px;
    margin-right: 10px;
}

.mweb .beneficios p {
    color: #82858b;
    text-align: center;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
    font-weight: 400;
    line-height: 168.187%;
    margin-bottom: 40px;
}

.mweb .beneficios p b {
    color: #69757f;
}

.mweb .beneficios .beneficio {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
}

.mweb .beneficios .beneficio div {
    text-align: left;
}

.mweb .beneficios .beneficio img {
    width: 180px;
    margin-right: 40px;
}

.mweb .beneficios .beneficio h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
}

.mweb .beneficios .beneficio h2.maestros {
    color: var(--amarillo);
}

.mweb .beneficios .beneficio h2.padres {
    color: var(--verde);
}

.mweb .beneficios .beneficio h2.orientadores {
    color: var(--rojo);
}

.mweb .beneficios .beneficio h2.directivos {
    color: var(--medium_grey);
}

.mweb .beneficios .beneficio ul {
    padding: 0;
    margin: 20px 0;
}

.mweb .beneficios .beneficio ul li {
    color: #82858b;
    font-size: 14px;
    font-weight: 400;
    line-height: 168.187%;
}

.mweb .beneficios .beneficio button {
    padding: 7px 24px;
    border-radius: 14px;
    border: 1px solid #82858b;
    background: transparent;
    color: #82858b;
}

/* pagina */
.mweb.beneficios .maestro,
.mweb.beneficios .padres {
    padding: 50px 0 20px;
    position: relative;
    background-image: url('./assets/bg_maestro.jpg');
    background-position: center;
    position: relative;
    background-size: 700px 440px;
}

.mweb.beneficios .padres {
    background-image: url('./assets/bg_fotter.jpg');
}

.mweb.beneficios .padres.directivo {
    background-image: url('./assets/bg_directivos.jpg');
}

.mweb.beneficios .maestro.orientador {
    background-image: url('./assets/bg_orientadores.jpg');
}

.mweb.beneficios .des {
    display: inline-flex;
    gap: 50px;
    width: 70%;
    color: #fff;
}

.mweb.beneficios .padres .container {
    display: flex;
    justify-content: right;
}

.mweb.beneficios .padres .des {
    width: 65%;
}

.mweb.beneficios .des li {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 20px;
}

.mweb.beneficios .des h1 img {
    margin-right: 10px;
}

.mweb.beneficios .des .inf h1 {
    color: #fff;
}

.mweb.beneficios .des .inf h2 {
    color: #fff;
}

.mweb.beneficios .app {
    width: 230px;
}

.mweb.beneficios .maestro .avatar,
.mweb.beneficios .padres .avatar {
    position: absolute;
    right: 0;
    background: var(--dark_grey);
    top: 0;
    height: 100%;
    padding-right: 50px;
}

.mweb.beneficios .padres .avatar {
    left: 0;
    right: inherit;
    padding: 0;
    padding-left: 100px;
}

.mweb.beneficios .maestro .avatar img {
    width: 310px;
    margin-left: -150px;
    margin-top: -30px;
}

.mweb.beneficios .maestro.orientador .avatar img {
    width: 250px;
}

.mweb.beneficios .padres .avatar img {
    width: 350px;
    margin-right: -150px;
    margin-top: -20px;
}

.mweb.beneficios .padres.directivo .avatar img {
    width: 280px;
}

@media (max-width: 1200px) {
    .mweb.beneficios .des .inf h1 {
        font-size: 40px;
    }

    .mweb.beneficios .container.center {
        margin-bottom: 60px;
    }
    .mweb.beneficios .maestro,
    .mweb.beneficios .padres,
    .mweb.beneficios .directivo,
    .mweb.beneficios .maestro.orientador {
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .mweb.beneficios .maestro .avatar,
    .mweb.beneficios .padres .avatar {
        background: none;
        width: 100%;
        height: auto;
        margin-top: -50px;
        display: flex;
        padding: 0;
        justify-content: center;
    }

    .mweb.beneficios .maestro .avatar img {
        width: 140px;
        margin-left: 0;
        margin-right: 0;
    }

    .mweb.beneficios .padres .avatar img {
        width: 170px;
        margin-left: 0;
        margin-right: 0;
    }

    .mweb.beneficios .padres.directivo .avatar img {
        width: 130px;
        margin-left: 0;
        margin-right: 0;
    }

    .mweb.beneficios .maestro.orientador .avatar img {
        width: 120px;
        margin-left: 0;
        margin-right: 0;
    }

    .mweb.beneficios .des {
        width: 100%;
    }

    .mweb.beneficios .des .inf {
        width: 100%;
    }

    .mweb.beneficios .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mweb.beneficios .des {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mweb.beneficios .des img.app {
        width: 100%;
        max-width: 300px;
        height: auto;
    }
}
/* Pre inscripción */

/* contacto */

.mweb .contacto {
    background-image: url('./assets/bg_beneficios.jpg');
    background-position: center;
    background-size: 700px 440px;
    background-repeat: repeat;
    padding: 70px 0;
}

.mweb h1 {
    color: #000000;
    font-size: 54px;
    font-weight: 700;
    text-align: center;
}

.mweb .contacto h1 img {
    margin-bottom: 10px;
    margin-right: 10px;
}

.mweb h2 {
    color: #000000;
}

.mweb h3 {
    color: #000000;
}

.mweb .contacto p {
    color: #82858b;
    text-align: center;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
    font-weight: 400;
    line-height: 168.187%;
    margin-bottom: 40px;
}

.mweb .contacto p b {
    color: #69757f;
}

.mweb .contacto .contacto {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
}

.mweb .contacto .contacto div {
    text-align: left;
}

.mweb .contacto .contacto img {
    width: 180px;
    margin-right: 40px;
}

.mweb .contacto .contacto h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
}

.mweb .contacto .contacto h2.maestros {
    color: var(--amarillo);
}

.mweb .contacto .contacto h2.padres {
    color: var(--verde);
}

.mweb .contacto .contacto h2.orientadores {
    color: var(--rojo);
}

.mweb .contacto .contacto h2.directivos {
    color: var(--medium_grey);
}

.mweb .contacto .contacto ul {
    padding: 0;
    margin: 20px 0;
}

.mweb .contacto .contacto ul li {
    color: #82858b;
    font-size: 14px;
    font-weight: 400;
    line-height: 168.187%;
}

.mweb .contacto .contacto button {
    padding: 7px 24px;
    border-radius: 14px;
    border: 1px solid #82858b;
    background: transparent;
    color: #82858b;
}

/* pagina */
.mweb.contacto .maestro,
.mweb.contacto .padres {
    padding: 50px 0 20px;
    position: relative;
    background-image: url('./assets/bg_maestro.jpg');
    background-position: center;
    position: relative;
    background-size: 700px 440px;
}

.mweb.contacto .padres {
    background-image: url('./assets/bg_fotter.jpg');
}

.mweb.contacto .padres.directivo {
    background-image: url('./assets/bg_directivos.jpg');
}

.mweb.contacto .maestro.orientador {
    background-image: url('./assets/bg_beneficios.jpg');
}

.mweb.contacto .des {
    display: inline-flex;
    gap: 50px;
    width: 70%;
    color: #fff;
}

.mweb.contacto .padres .container {
    display: flex;
    justify-content: right;
}

.mweb.contacto .padres .des {
    width: 65%;
}

.mweb.contacto .des li {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 20px;
}

.mweb.contacto .des h1 img {
    margin-right: 10px;
}

.mweb.contacto .app {
    width: 230px;
}

.mweb.contacto .maestro .avatar,
.mweb.contacto .padres .avatar {
    position: absolute;
    right: 0;
    background: var(--dark_grey);
    top: 0;
    height: 100%;
}

.mweb.contacto .padres .avatar {
    left: 0;
    right: inherit;
    padding: 0;
    padding-left: 100px;
}

.mweb.contacto .maestro .avatar img {
    width: 310px;
    margin-left: -150px;
    margin-top: -30px;
}

.mweb.contacto .maestro.orientador .avatar img {
    width: 250px;
}

.mweb.contacto .padres .avatar img {
    width: 350px;
    margin-right: -150px;
    margin-top: -20px;
}

.mweb.contacto .padres.directivo .avatar img {
    width: 280px;
}

@media (max-width: 907px) {
    .mweb.contacto .avatar {
        position: static;
        background: none;
        margin-top: 60px;
    }

    .mweb.contacto .des {
        width: 100%;
    }
    .mweb.contacto .maestro .avatar {
        position: static;
        display: grid;
        margin-bottom: -20px;
        align-items: center;
        justify-content: center;
    }

    .mweb.contacto .maestro .avatar img {
        width: auto;
        margin-right: 0;
        margin-left: 0;
        margin-top: -80px;
    }
}
/* funcionalidades */
.funcionalidades {
    padding: 30px 0;
    background: var(--rojo);
    color: #fff;
}

.funcionalidades .custom-container {
    display: flex;
    justify-content: center;
}

.funcionalidades .content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}

.funcionalidades .asesor {
    width: 220px;
    margin-right: 60px;
}

.funcionalidades h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

.funcionalidades p {
    font-size: 30px;
    line-height: 1.6;
    text-align: justify;
}

.funcionalidades button {
    border: 1px solid #fff;
    background: transparent;
    border-radius: 14px;
    color: #fff;
    padding: 10px 30px;
    cursor: pointer;
    font-size: 20px;
}

@media (max-width: 768px) {
    .funcionalidades .content {
        flex-direction: column;
        text-align: center;
        margin: 20px;
    }

    .funcionalidades .asesor {
        margin-top: 40px;
        margin-right: 0;
        order: 1;
    }

    .funcionalidades h1 {
        font-size: 30px;
    }

    .funcionalidades p {
        font-size: 20px;
        line-height: 1.6;
    }
}
/* funcionalidades page */
.mweb .funcionalidades_page {
    background: var(--rojo);
    padding: 150px 0;
    position: relative;
    color: #fff;
}

.mweb .funcionalidades_page .separator {
    position: absolute;
    top: 0;
    width: 1px;
    left: 50%;
    background: #fff;
    height: 100%;
}

.mweb .funcionalidades_page .avatar_top,
.mweb .funcionalidades_page .avatar_bottom {
    position: absolute;
    top: -20px;
    width: 160px;
    left: 50%;
    margin-left: -80px;
    z-index: 9;
}

.mweb .funcionalidades_page .avatar_bottom {
    width: 220px;
    margin-left: -110px;
    bottom: -50px;
    top: inherit;
}

.mweb .funcionalidades_page .funcion {
    padding: 0 100px;
    line-height: 168.187%;
    font-size: 18px;
    margin-bottom: 40px;
}

.mweb .funcionalidades_page .funcion h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 20px;
    color: #fff;
}

@media (max-width: 768px) {
    .mweb .funcionalidades_page .funcion {
        padding: 0 60px;
    }
    .mweb .funcionalidades_page .separator {
        display: none;
    }
    .mweb .funcionalidades_page .avatar_top {
        width: 100px;
        top: -40px;
        left: 57%;
    }
    .mweb .funcionalidades_page .avatar_bottom {
        width: 190px;
        left: 54%;
    }
}

/* videos */
.mweb .videos {
    padding: 100px 0;
    background: #232630;
    color: #fff;
}

.mweb .videos h1 {
    font-size: 54px;
    font-weight: 700;
    margin: 0 0 20px;
    text-align: center;
    color: #fff;
}

.mweb .videos h1 img {
    margin-bottom: 10px;
    margin-right: 10px;
}

.mweb .videos span {
    text-align: center;
    display: block;
    color: #d0d4db;
    font-weight: 400;
    font-size: 18px;
}

.mweb .videos .textos {
    display: flex;
    align-items: center;
    gap: 50px;
}

.mweb .videos .textos p {
    color: #d0d4db;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 168.187%;
}

.mweb .videos .textos p b {
    color: #fff;
}

.mweb .videos a {
    color: #a4a7af;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    display: block;
}

.mweb .videos a img {
    width: 15px;
    margin-right: 10px;
}

.mweb #owl-demo {
    margin: 30px 0;
}

.mweb .owl-carousel .owl-stage-outer {
    height: 200px;
}

.mweb #owl-demo a img {
    width: 100%;
    border-radius: 16px;
}

.mweb #owl-demo .owl-item {
    margin: 0 10px;
}

/* ANT DESING CAROUSEL */

.mweb .antd-carousel .slick-slide {
    padding: 10px;
}

.mweb .antd-carousel .slick-slide > div {
    border-radius: 10px;
    overflow: hidden;
}

.mweb .antd-carousel .slick-slide img {
    width: 100%;
    height: auto;
}

/* descargas */
.mweb .descargas {
    background-image: url('./assets/bg_descargas.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0 100px;
}

.mweb .descargas h4 {
    color: #a4a7af;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.mweb .descargas .grid img {
    margin-bottom: 30px;
    margin: 0 auto;
}

.descargas .tiendas {
    display: flex;
    justify-content: center;
}

.descargas .tiendas img {
    max-width: 250px;
}

/* footer */
.mweb footer {
    background-image: url('./assets/bg_fotter.jpg');
    background-position: center;
    padding: 100px 0 0;
    position: relative;
    color: #fff;
    background-size: 700px 440px;
    background-repeat: repeat;
}

.mweb footer .palomita {
    position: absolute;
    width: 90px;
    top: -40px;
    left: 50%;
    margin-left: -45px;
}

.mweb footer .FBeneficios h4 {
    color: #fff;
}

.mweb footer .FFuncionalidades h4 {
    color: #fff;
}

footer .infoot {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.mweb footer .bottom {
    background: rgba(0, 0, 0, 0.15);
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    margin-top: 50px;
}

.mweb footer ul {
    padding-left: 25px;
    text-align: left;
}

.mweb footer li {
    font-size: 14px;
    margin-bottom: 10px;
}

.mweb footer h4 {
    text-align: left;
    font-size: 16px;
}

.mweb footer h3 {
    text-align: left;
    font-size: 30px;
    margin: 10px 0;
}

.mweb footer h2 {
    text-align: left;
    font-size: 27px;
    margin: 10px 0;
    color: #fff;
}

.mweb footer .redes {
    padding: 0;
    margin: 0;
}

.mweb footer .redes li {
    display: inline-block;
    list-style: none;
    margin-right: 15px;
}

.mweb footer p {
    font-size: 16px;
    text-align: left;
    line-height: 130%;
}

.mweb footer p.dir {
    font-size: 14px;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
    .mweb .menu ul {
        flex-direction: column;
    }

    .mweb .menu li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .mweb .menu {
        display: none;
    }

    .mweb .mobile-menu-toggle {
        display: flex;
    }

    .mweb .menu.active {
        display: flex;
        flex-direction: column;
        background-color: #2e333e;
        position: absolute;
        top: 75px;
        left: 0;
        width: 100%;
    }

    .mweb .menu.active ul {
        padding: 0;
    }

    .mweb .menu.active li {
        text-align: center;
        margin: 10px 0;
    }

    /* Animación del icono de hamburguesa */
    .mweb .mobile-menu-toggle.active .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .mweb .mobile-menu-toggle.active .bar:nth-child(2) {
        opacity: 0;
    }

    .mweb .mobile-menu-toggle.active .bar:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

/* MEDIA QUERIES */
/* -- -- -- -- -- -- -- */
@media (max-width: 1200px) {
    /**/
    .textos {
        padding: 0 40px;
        flex-direction: column;
    }

    .grid_beneficios {
        padding: 0 30px;
    }

    footer .infoot {
        padding: 0 20px 0 30px;
        /*1200*/
    }

    @media (max-width: 992px) {
        footer .infoot {
            grid-template-columns: 60% 40%;
            padding: 0 20px 0 40px;
        }

        footer .infoot > div {
            padding-right: 30px;
        }
    }

    /*992*/

    @media (max-width: 767px) {
        /**/
        footer .infoot {
            grid-template-columns: 1fr;
            text-align: center;
        }

        footer .infoot p,
        footer .infoot h2 {
            text-align: center;
        }

        footer .infoot .redes {
            display: flex;
            justify-content: center;
        }

        .menufoot {
            display: none;
        }

        /*768*/
    }
}

.custom-container {
    max-width: 1100px;
    margin: 0 auto;
    /* Centra el contenedor */
}

/* MEDIA QUERIES */
/* -- -- -- -- -- -- -- */
@media (max-width: 992px) {
    .custom-container {
        max-width: 740px;
    }

    .mweb .sidebar .maestra img {
        width: 350px;
        padding-left: 20px;
    }

    .mweb .beneficios .beneficio img {
        width: 40%;
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    /**/
}

.mweb footer .FFuncionalidades a {
    color: #fff;
}

.mweb footer .FFuncionalidades a:hover {
    color: #ff6969;
}

.mweb footer .FFuncionalidades nav ul li a {
    color: #fff;
}

.mweb footer .FFuncionalidades nav ul li a:hover {
    color: #ff6969;
}

.mweb footer .FBeneficios a {
    color: #fff;
}

.mweb footer .FBeneficios a:hover {
    color: #ff6969;
}

.mweb footer .FBeneficios nav ul li a {
    color: #fff;
}

.mweb footer .FBeneficios nav ul li a:hover {
    color: #ff6969;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    text-decoration: none;
}

.ant-picker-dropdown {
    z-index: 12123123123123123 !important;
}

.ct-bar-label-black {
    font-size: 15px;
    font-weight: bold;
    fill: rgb(0, 0, 0);
}

.ct-label {
    fill: rgb(0 0 0) !important;
    color: rgb(0 0 0) !important;
}

.dot {
    background: red;
    width: 0.7em;
    height: 0.7em;
    border-radius: 0.7em;
}

.ct-chart-bar-vertial .ct-label-detail {
    font-size: 11px;
    font-weight: bold;
    fill: rgb(0, 0, 0);
}

.ct-chart-bar-vertial .ct-label-detail-light {
    font-size: 11px;
    font-weight: bold;
    fill: rgb(247, 247, 247);
}

.ct-chart-bar-vertial .ct-label-detail-small {
    font-size: 10px;
    font-weight: bold;
    fill: rgb(0, 0, 0);
}

.ct-chart-bar-vertial svg.ct-chart-bar,
svg.ct-chart-line {
    overflow: visible;
}

.ct-chart-bar-vertial .ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-80deg) !important;
    white-space: nowrap;
}

.ct-tooltip {
    background-color: rgb(22, 126, 211);
    width: 200px;
    height: 200px;
    z-index: 11111111111111 !important;
}

.datagrid-app-purple {
    background-color: #7434a3;
    color: white;
}

.datagrid-app-blue {
    background-color: #0099cc;
    color: white;
}

.datagrid-app-red {
    background-color: #fc4444;
    color: white;
}

.datagrid-app-green {
    background-color: #04b454;
    color: white;
}

.datagrid-app-orange {
    background-color: #fc6404;
    color: white;
}

.working .box {
    width: 100%;
    height: 100vh;
    background: url('./public/assets/img/inicio.png') top right no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-border-green {
    border-left: green;
    border-left-style: solid;
}
